export const btnText = {
  downloadCSV: "Download CSV",
  submit: "Submit",
  clear: "Clear",
};

export const logoutKey = "logout";
export const logoutText = "Logout";

export const texts = {
  healthplan: "Healthplan",
  state: "State",
  pcp: "PCP",
  visitStart: "Visit Start Date",
  visitEnd: "Visit End Date",
  visitingPhysician: "Visiting Physician",
  memberDispo: "Member Disposition",
  callDispo: "Call Disposition",
  memberID: "Member ID",
  memberName: "Member Name",
  visitID: "Visit ID",
  callOperator: "Call Operator",
  callCount: "Call Count",
};
