export const authURL = {
  login: `login`,
  validateToken: `validate-token`,
};

export const apiURL = {
  //dailer
  getMemberCallHistory: `getMemberCallHistory`,
  getDispositionLookup: `getDispositionLookup`,
  getMemberInfo: `getMemberInfo`,
  getMemberVisitInfo: `getMemberVisitInfo`,
  createCall: `createCall`,
  finishCall: `finishCall`,
  getMemberCallHistory: `getMemberCallHistory`,
  //export
  getMemberExportData: `getMemberExportData`,
  getState: `getState`,
  getHealthplan: `getHealthplan`,
  getPcp: `getPcp`,
  getAccountProject: `getAccountProject`,
  getVisitingPhysician: `getVisitingPhysician`,
  getLookUp: `getLookUp`,
  //five-9
  disconnectCall: `disconnectCall`,
  disposeCall: `disposeCall`,
};
